.projects-section {
    padding          : 2em 0;
    display          : -webkit-flex;
    display          : -ms-flex;
    display          : flex;
    background-image : url("//images.prismic.io/lick/f00d6ccc-50f0-4849-b2cd-8adbf118ddd3_Paint-Wall-Swatch---Beige-01.jpg?auto=compress,format&rect=0,0,2560,1648&w=1600&h=1030");
    background-repeat: no-repeat;
    background-size  : cover;
    /* height : 750px; */
    .row {
        .cards {
            display        : -webkit-flex;
            display        : -ms-flex;
            display        : flex;
            align-items    : center;
            justify-content: center;
        }
    }

    .fx-wrap {
        background-repeat: no-repeat;
        background-size  : contain;
        display          : inline-block;
        height           : 550px;
        margin           : 0 !important;
        overflow         : hidden;
        padding          : 5px 15px;

        .card {
            perspective     : 1000px;
            width           : 324px;
            float           : left;
            background-color: transparent;
            border          : none;
            margin          : 40px auto;
            transform       : rotateY(0deg);
            transition      : height 0.3s ease-out;
            height          : 204px;

            & > .front {
                z-index            : 2;
                transform          : rotateY(0deg);
                /* transition         : height 0.3s ease-out; */
                /* height             : 204px; */
                background-size    : 150px;
                background-position: 15px 15px;

                .image {
                    border-top-left-radius   : 4px;
                    border-bottom-left-radius: 4px;
                    position                 : absolute;
                    bottom                   : 15px;
                    right                    : 0;
                    height                   : 20px;
                    width                    : 90px;
                    background-color         : #003f88;
                }

                .text {
                    h4,
                    p {
                        position: absolute;
                        top     : 120px;
                        left    : 15px;
                    }

                    p {
                        top: 150px;
                    }

                    a {
                        color      : #fff;
                        font-weight: bold;
                        z-index    : 1;
                    }
                }
            }

            & > .back {
                z-index  : 1;
                transform: rotateY(180deg);
                /* transition: height 0.3s ease-out; */
                /* height    : 450px; */
                padding  : 10px;
                overflow : hidden;

                .image {
                    float                    : left;
                    border-top-left-radius   : 4px;
                    border-bottom-left-radius: 4px;
                    background-size          : cover;
                    width                    : 108px;
                    background-color         : rgba(0,0,0,0.2);
                    position                 : relative;
                }

                .text {
                    overflow: hidden;

                    h4 {
                        margin-bottom: 0;
                    }

                    p {
                        display : inline-block;
                        margin  : 15px auto;
                        overflow: hidden;
                        width   : 98%;
                    }
                }
            }

            &.flip-it {
                transition: height 0.3s ease-out;

                & > .front {
                    transform: rotateY(-180deg);
                    z-index  : 1;
                }

                & > .back {
                    transform: rotateY(0deg);
                    z-index  : 2;

                    .text {
                        overflow: hidden;

                        h4 {
                            margin-bottom: 0;
                            text-align   : right;
                        }

                        b {
                            display   : block;
                            text-align: right;
                        }

                        p {}
                    }
                }
                transform : rotateY(0deg);
                transition: height 0.3s ease-out;
                height    : 450px;
            }
        }
    }

    .fx-wrap .card > .back,
    .fx-wrap .card > .front {
        transition         : transform 3s, z-index 3s 0s, box-shadow 3s;
        box-shadow         : 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        will-change        : transform;
        position           : absolute;
        left               : 0;
        right              : 0;
        top                : 0;
        bottom             : 0;
        border-radius      : 4px;
        background-color   : #fff;
        background-repeat  : no-repeat;
        backface-visibility: hidden;
    }

    .fx-wrap .card > .back:before,
    .fx-wrap .card > .front:before {
        transition         : all 1s;
        content            : '';
        display            : block;
        position           : absolute;
        width              : 100%;
        height             : 100%;
        background-size    : contain;
        background-image   : linear-gradient(135deg, rgba(238,238,238,0) 0%, rgba(255,255,255,0) 36%, rgba(255,255,255,0.4) 36%, rgba(255,255,255,0.4) 68%, rgba(247,247,247,0) 69%, rgba(238,238,238,0) 70%);
        background-repeat  : no-repeat;
        background-position: 324px 0;
        pointer-events     : none;
        z-index            : 20;
    }

    .fx-wrap .card > .back > *,
    .fx-wrap .card > .front > * {
        height: 100%;
    }

    .fx-wrap .card > .back .text,
    .fx-wrap .card > .front .text {
        display : block;
        width   : 100%;
        position: relative;
        padding : 5px;
    }

    .fx-wrap .card > .back .text p,
    .fx-wrap .card > .front .text p {
        width    : 100%;
        flex     : 2;
        font-size: 0.8em;
    }

    .fx-wrap .card > .back .text a,
    .fx-wrap .card > .front .text a {
        text-decoration: none;
        text-align     : right;
        width          : 80%;
        display        : block;
        flex           : 1;
        position       : absolute;
        bottom         : 15px;
        right          : 15px;
    }

    .fx-wrap .card > .back .image .heading,
    .fx-wrap .card > .front .image .heading {
        position      : absolute;
        bottom        : 0;
        left          : 0;
        right         : 0;
        margin        : 0;
        padding       : 10px;
        font-size     : 1.5em;
        text-align    : right;
        color         : #fff;
        text-transform: uppercase;
    }

    .fx-wrap .card > .back .text,
    .fx-wrap .card > .back header {
        width: 100%;
    }

    .fx-wrap .card.flip-it > .back:before,
    .fx-wrap .card.flip-it > .front:before {
        background-position: -324px 0;
    }

    .fx-wrap .card > .back .text button.flip,
    .fx-wrap .card > .front .text button.flip {
        border-radius   : 9999em;
        width           : 25px;
        height          : 25px;
        margin          : 10px;
        border          : 0;
        background-color: #ddd;
        color           : #fff;
        font-size       : 1.5em;
        float           : right;
        flex            : 1;
        outline         : 0;
        /* transition      : background-color 0.3s ease-in, transform 0.4s; */
        transition-delay: 0.1s;
        background      : rgb(226,226,226);
        background      : radial-gradient(circle at top right, rgb(226,226,226) 0%, rgb(255,255,255) 80%);
        box-shadow      : -2px 2px 5px rgba(0,0,0,.03);
    }

    .fx-wrap .card > .back .text button.flip span,
    .fx-wrap .card > .front .text button.flip span {
        color         : #ddd;
        text-align    : left;
        font-size     : 1em;
        margin-bottom : 5px;
        vertical-align: super;
    }

    .fx-wrap .card > .back .text button.flip:hover,
    .fx-wrap .card > .front .text button.flip:hover {
        background-color: #16a8cf;
    }

    .fx-wrap .card > .back .text button.flip:focus,
    .fx-wrap .card > .front .text button.flip:focus {
        transform: rotate(-360deg);
    }
}